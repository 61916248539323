import React from 'react';
import Navigation from '../components/navigation';
import Layout from "../components/layout";
import SEO from "../components/seo";
import '../scss/contact.scss';
import Footer from '../components/footer';


const contact = () => (
  <Layout>
    <SEO title="Contact" keywords={[`photographer`, `fotograf`, `dusseldorf`, `düsseldorf`, `germany`, `写真家`, `portrait`, `porträt`, `カメラマン`, `TFP`, `landscape`, `hobby`, `japanese`, `street`, `outdoor`]} />
    <Navigation contact={true} />

    <div className='contact'>
      <div className='contact__section'>
        <div className='contact__form'>
          <h2>Contact</h2>
          <div className='inner'>
            <form method='post' name='contact' action='/thanks' data-netlify='true' netlify-honeypot='bot'>
            <input type='hidden' name='form-name' value='contact' />
            <div className="field__hidden" style={{display: "none"}}>
              <label>Don't fill this out, human</label>
              <input name='bot' />
            </div>  

            <div className="form-wrapper">
              <div className='field'>
                <label>*Name</label>
                <input type='text' name='name' required />
              </div>
              <div className='field'>
                <label>organization</label>
                <input type='text' name='organization' />
              </div>
            </div>

            <div className="form-wrapper">
              <div className='field'>
                <label>*Email</label>
                <input type='text' name='email' required />
              </div>
              <div className='field'>
                <label>Tel</label>
                <input type='tel' name='tel' />
              </div>
            </div>
           
            <div className='field text-field'>
              <label>Message</label>
              <textarea name='message' rows='6'></textarea>
            </div>
            <div className='submit'>
              <button type='submit' className='btn__med'>Send</button>
            </div>
            </form>
          </div>
        </div>
      </div>
    </div>

    <Footer />
  </Layout>
);

export default contact;
